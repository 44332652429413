<template>
  <div class="bankCard">
    <mt-header :title="$t('bankCard')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <div v-if="!bankCard.length" class="listNull">
        <img src="../../../assets/emptyBankCard.png" height="108px" style="margin-top: 80px;">
        <div class="tips">{{ $t('noBankCard') }}</div>
        <div class="bottom">
          <mt-button type="primary" @click="addBankCard">{{
            $t("addBankCard")
          }}</mt-button>
        </div>
      </div>
      <template v-else>
        <div class="bankList">
          <div class="content">
            <div class="item" v-for="(item, index) in bankCard" :key="index" @click="$router.push({ path: '/mine/bankInfo', query: { id: item.id, bank: item.bank, last4: item.last4 } })">
              <img class="logo" src="../../../assets/logo.png">
              <div class="name">{{ item.bank }}</div>
              <div class="number">****{{ item.last4 }}</div>
            </div>
          </div>
          <div class="bottom">
            <mt-button type="primary" size="large" @click="addBankCard">{{
              $t("addBankCard")
            }}</mt-button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { bindCardList, getPayPlatAddress } from '../../../utils/api'
import callPhone from '@/components/callPhone.vue'
import { mapState, mapMutations } from "vuex";
export default {
  name: 'BankCard',
  components: {
    callPhone
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapState(["user", "bankCard"]),
  },
  created() {
    this.init()
  },
  methods: {
    ...mapMutations(['SET_BANK_CARD']),
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await this.getBankCard()
      this.$indicator.close();
    },
    async getBankCard() {
      await this.$axios({
        method: 'post',
        url: bindCardList,
      })
        .then((e) => {
          if (e.status.code == "000") {
            this.SET_BANK_CARD(e.body)
          }
        })
        .catch(() => {})
    },
    async addBankCard() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await this.$axios({
        method: "post",
        url: getPayPlatAddress,
        data: {
          applyId: this.user.applyId,
          payType: 'LINK_BINDING_CARD',
        }
      }).then((e) => {
        if (e.status.code == "000") {
          window.location.href = e.body.webUrl
        }
      }).catch(() => {})
      this.$indicator.close();
    }
  }
}
</script>

<style lang="scss" scoped>
.bankCard {
  height: 100vh;
  display: flex;
  flex-direction: column;
  > .content {
    flex: 1;
    .listNull {
      padding: 20px 0px;
      text-align: center;
      .icon {
        font-size: 200px;
        margin: 40px 10px 10px 10px;
      }
      .tips {
        color: #868686;
      }
      .bottom {
        padding: 40px 20px 20px 20px;
        text-align: center;
        .mint-button {
          border-radius: 46px;
          padding: 0 22px;
        }
      }
    }
    .bankList {
      height: 100%;
      display: flex;
      flex-direction: column;
      .content {
        padding: 0px 40px;
        overflow: auto;
        flex: 1;
        .item {
          margin-top: 25px;
          height: 80px;
          border-radius: 10px;
          background: linear-gradient(0deg, #47B526 14.20898%, #82e863 100%);
          padding: 10px 15px;
          display: flex;
          font-size: 15px;
          color: #FFFFFF;
          font-weight: bold;
          line-height: 24px;
          box-shadow: -1px 1px 5px 1px #e6e6e6;
          .logo {
            height: 33px;
            margin-right: 10px;
          }
          .name {
            flex: 1;
          }
        }
      }
      .bottom {
        padding: 20px 40px 40px 40px;
        text-align: center;
        .mint-button {
          border-radius: 46px;
          padding: 0 22px;
        }
      }
    }
  }
}
</style>